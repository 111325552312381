


















































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import moment from 'moment'
import algoliasearch from 'algoliasearch'
import Common from '../../common/Common'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Paginate from 'vuejs-paginate'
import removeMd from 'remove-markdown'
require("moment-duration-format")

@Component({
  components: {
    ClipLoader,
    Paginate
  },
})
export default class ClassSearch extends Vue {
  LOAD_COUNT = 10
  rMd = removeMd
  isLoading = true
  viewDetailSearchTab = false
  category = ''
  categories: any[] = []
  viewUserName = ''
  searchUid = ''
  searchCategory = ''
  searchSort = 'date'
  searchValue = ''
  searchPrefecture = ''
  searchReward = ''
  searchRate = ''
  questions: {id: string, title: string, detail: string, tags: string[], date: string, userName: string}[] = []
  alreadyReadResult: any = ''
  index = algoliasearch(Common.ALGORIA_APP_ID, Common.ALGORIA_API_KEY).initIndex('onayamis')
  rateIndex = algoliasearch(Common.ALGORIA_APP_ID, Common.ALGORIA_API_KEY).initIndex('onayamis_performance')
  pages = 1
  page = 1

  clickCallback(pageNum: number) {
    this.isLoading = true
    this.page = pageNum
    this.$router.replace({name: 'onayamiSearch', query: {page: String(pageNum), text: this.searchValue, category: this.$route.query.category, uid: this.searchUid}})
    this.search()
  }

  @Watch('$route')
  changeRouter() {
    if (this.category) {
      if (!this.$route.query.category) {
        if (this.category == 'すべてのカテゴリー') {
          return
        }
      } else {
        let category = this.categories.find((category: any) => {
          return category.value == this.$route.query.category
        }).text

        if (category == this.category) {
          return
        }
      }
    }
    this.searchValue = ''
    this.searchSort = 'date'
    this.searchReward = ''
    this.init()
  }

  mounted() {
    this.init()
  }

  init() {
    this.$emit('update:viewCsCategory', true)
    this.$emit('update:viewTcCategory', false)
    this.$emit('update:viewQsCategory', false)
    this.$emit('update:searchType', 'onayami')
    if (this.$route.query.page) {
      this.page = Number(this.$route.query.page)
    } else {
      sessionStorage.removeItem('onayamiSearch')
    }

    if (this.$route.query.text) {
      this.searchValue = <string>this.$route.query.text
      this.searchPrefecture = <string>this.$route.query.prefecture
      this.$emit('update:searchValue', this.searchValue)
    } else {
      this.$emit('update:searchValue', '')
    }

    let session: any = sessionStorage.getItem('onayamiSearch')
    if (session) {
      session = JSON.parse(session)
      this.searchSort = session.searchSort ? session.searchSort : this.searchSort
      this.searchReward = session.searchReward ? session.searchReward : this.searchReward
      if (session.searchReward) {
        this.viewDetailSearchTab = true
      }
    }

    if (this.$route.query.uid) {
      this.searchUid = <string>this.$route.query.uid
    }
    
    Common.getCategory().then((categories: any) => {
      this.categories = categories
      if (this.$route.query.category) {
        this.category = categories.find((category: any) => {
          return category.value == this.$route.query.category
        }).text
        this.searchCategory = <string>this.$route.query.category
      } else {
        this.category = 'すべてのカテゴリー'
        this.searchCategory = ''
      }
      this.search()
    })
  }

  setQuestion(data: any[]) {
    data.forEach((value, index) => {
      let obj: any = {id: value.objectID, title: value.title, detail: value.detail, tags: value.tags, date: value.date, reward: value.reward, category: value.category, finish:value.finish, roomCount: value.roomCount ? value.roomCount : 0, rate: value.rate, rateNum: value.rateNum, userName: value.userName, restSlot: value.peopleLimit - value.inClass, createTime: new Date(value.createTime), uid: value.uid}
      obj.url = Common.createUserIconURL(obj.uid, value.userFileExist)
      obj.rateIcons = Common.createRateIcon(obj.rate)
      this.questions.push(obj)
    })

    if (this.searchUid) {
      this.viewUserName = this.questions.length == 0 ? '' : this.questions[0].userName
    }
  }

  select(question: firebase.firestore.DocumentData) {
    this.$router.push(`/onayami/${question.id}`)
  }

  search(conditionChange: boolean = false) {
    this.isLoading = true

    let index = this.index
    if (this.searchSort == 'performance') {
      index = this.rateIndex
    }

    if (conditionChange) {
      this.$router.replace('/onayami/search?page=1&category=' + this.searchCategory)
      sessionStorage.setItem('onayamiSearch', JSON.stringify({searchSort: this.searchSort, searchReward: this.searchReward}))
    }
    index
      .search({
        query: this.searchValue,
        filters: this.createFilterStr(),
        hitsPerPage: this.LOAD_COUNT,
        page: this.page - 1
      })
      .then((responses: any) => {
        this.questions = []
        this.setQuestion(responses.hits)
        this.pages = Math.ceil(responses.nbHits / this.LOAD_COUNT)
      }).finally(() => {
        this.isLoading = false
      })
  }

  createFilterStr() {
    let filterStr = ''
    if (this.searchReward) {
      filterStr += `reward <= ${this.searchReward}`
    }
    if (this.searchCategory) {
      let category = this.categories.find((category: any) => {
        return category.value == this.searchCategory
      }).text
      filterStr += filterStr ? ' AND ' : ''
      filterStr += `category:"${category}"`
    }
    // 都道府県フィルタ
    if (this.searchPrefecture) {
      filterStr += filterStr ? ' AND ' : ''
      filterStr += `prefecture:"${this.searchPrefecture}"`
    }
    if (this.searchUid) {
      filterStr += filterStr ? ' AND ' : ''
      filterStr += `uid:"${this.searchUid}"`
    }
    if (this.searchRate) {
      filterStr += filterStr ? ' AND ' : ''
      filterStr += `rate >= ${this.searchRate}`
    }
    filterStr += filterStr ? ' AND ' : ''
    filterStr += 'finish=0'
    filterStr += ' AND type:"class"'
    return filterStr
  }

  sortClick(type: string) {
    if (this.searchSort != type) {
      this.searchSort = type
      this.search(true)
    }
  }
}
